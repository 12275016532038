<template>
  <section id="revenue-margin">
    <b-card>
      <b-row>
        <b-col xl="2" md="2">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showRevenueMargin" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-row>
        <b-col xl="8">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Revenue profit
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-revenue-profit" />
              </h4>
              <b-popover target="popover-revenue-profit" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="revenueProfitChart" :options="revenueProfitChart" style="width: 100%; height: 500px;" />
          </b-card>
        </b-col>
        <b-col xl="4">
          <b-card no-body>
            <b-table striped hover responsive class="position-relative" :current-page="revenueProfitTable.currentPage"
              :per-page="10" :items="revenueProfitTable.items" :fields="revenueProfitTable.fields"
              :sort-by.sync="revenueProfitTable.sortBy" :sort-desc.sync="revenueProfitTable.sortDesc"
              :sort-direction="revenueProfitTable.sortDirection" :filter="revenueProfitTable.filter"
              :filter-included-fields="revenueProfitTable.filterOn">
              <template #cell(profitMargin)="data">
                {{ (data.item.profit * 100 / data.item.turnover).toFixed(2) }}
              </template>
            </b-table>
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="revenueProfitTable.currentPage" :total-rows="revenueProfitTable.totalRows"
                  first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCard,
  BCardBody,
  BCardHeader,
  BPopover,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';
import ECharts from 'vue-echarts';

import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';

const currentDate = new Date();
const twelveMonthsAgo = new Date();
twelveMonthsAgo.setMonth(currentDate.getMonth() - 11);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardBody,
    BCardHeader,
    BPopover,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
    ECharts,
  },
  data() {
    return {
      showRevenueMargin: true,
      oldDateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      queryParamsRevenueMargin: {},
      revenueProfitChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      revenueProfitTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'month_year',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          {
            key: 'turnover',
            label: 'revenue',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'profit',
            label: 'profit',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'profitMargin',
            label: 'profit margin',
            sortable: true,
            formatter: val => `${val} %`,
          },
          {
            key: 'month_year',
            label: 'date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    this.queryParamsRevenueMargin.from_date = dateRange[0];
    this.queryParamsRevenueMargin.to_date = dateRange[1];

    try {
      await this.getChartsData();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    updateCharts(data) {
      this.revenueProfitChart.xAxis.data = data.map(item => new Date(item.month_year).toLocaleDateString('de-DE'));
      this.revenueProfitChart.series[0] = {
        name: 'Revenue',
        type: 'line',
        areaStyle: {},
        data: data.map(item => item.turnover),
      };
      this.revenueProfitChart.series[1] = {
        name: 'Profit',
        type: 'line',
        areaStyle: {},
        data: data.map(item => item.profit),
      };
      this.revenueProfitTable.items = data;
      this.revenueProfitTable.totalRows = data.length * 2;
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getChartsData() {
      this.showRevenueMargin = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-profit/`, this.queryParamsRevenueMargin);
        this.updateCharts(response.data.results);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showRevenueMargin = false;
      }
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParamsRevenueMargin.from_date = dateRange[0];
        this.queryParamsRevenueMargin.to_date = dateRange[1];
        await this.getChartsData();
        this.oldDateRange = this.dateRange;

        this.$nextTick(() => {
          this.$refs.revenueProfitChart.refresh();
        });
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
